// src/components/DoctorDashboard.jsx

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { IconButton, Button, TextField } from '@mui/material';
import {
  AddCircleOutline,
  RemoveCircleOutline,
  ArrowDropDown,
  ArrowDropUp,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMaskInput } from 'react-imask';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

const DAYS_OF_WEEK = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

// Masked input component for date
const DateMaskInput = React.forwardRef(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00.00.0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const DoctorDashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [doctor, setDoctor] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [isPersonalInfoCollapsed, setPersonalInfoCollapsed] = useState(true);
  const [isScheduleCollapsed, setScheduleCollapsed] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [editAppointment, setEditAppointment] = useState(null);
  const [errors, setErrors] = useState({});

  const fetchDoctorData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/doctors/${id}`
      );
      const now = dayjs();
      const twoWeeksLater = now.add(14, 'day');

      // Фильтрация записей на ближайшие 14 дней
      const filteredAppointments = response.data.appointments.filter((appointment) => {
        const appointmentDateTime = dayjs.tz(
          `${appointment.date} ${appointment.time}`,
          'DD.MM.YYYY HH:mm',
          'Europe/Moscow'
        );
        return appointmentDateTime.isBetween(now, twoWeeksLater, 'minute', '[]');
      });

      setDoctor(response.data);
      setAppointments(
        filteredAppointments.sort((a, b) => {
          const dateTimeA = dayjs.tz(`${a.date} ${a.time}`, 'DD.MM.YYYY HH:mm', 'Europe/Moscow');
          const dateTimeB = dayjs.tz(`${b.date} ${b.time}`, 'DD.MM.YYYY HH:mm', 'Europe/Moscow');
          return dateTimeA.diff(dateTimeB);
        })
      );
      setSchedule(response.data.schedule || {});
    } catch (error) {
      console.error('Ошибка при получении данных доктора:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchDoctorData();
  }, [fetchDoctorData]);

  const handleLogout = () => {
    sessionStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('doctorId');
    navigate('/doctor-login');
  };

  const handleDataChange = (e) => {
    setDoctor({ ...doctor, [e.target.name]: e.target.value });
  };

  const handleSaveData = async () => {
    try {
      const formData = new FormData();
      formData.append('name', doctor.name);
      formData.append('email', doctor.email);
      formData.append('specialty', doctor.specialty);
      formData.append('experience', doctor.experience);
      formData.append('about', doctor.about);
      formData.append('education', doctor.education);
      formData.append('schedule', JSON.stringify(schedule));

      if (photo) {
        formData.append('photo', photo);
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/doctors/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      fetchDoctorData();
      alert('Данные успешно обновлены');
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
    }
  };

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleAddTimeSlot = (day) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      return {
        ...prevSchedule,
        [day]: [...daySchedule, { start: '', end: '' }],
      };
    });
  };

  const handleRemoveTimeSlot = (day, index) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      daySchedule.splice(index, 1);
      // Если после удаления массив пуст, удаляем день из расписания
      if (daySchedule.length === 0) {
        const { [day]: _, ...rest } = prevSchedule;
        return rest;
      }
      return {
        ...prevSchedule,
        [day]: [...daySchedule],
      };
    });
  };

  const handleScheduleChange = (day, index, field, value) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[day] || [];
      const updatedTimeSlot = {
        ...daySchedule[index],
        [field]: value ? parseInt(value) : '',
      };
      daySchedule[index] = updatedTimeSlot;
      return {
        ...prevSchedule,
        [day]: [...daySchedule],
      };
    });
  };

  const handleEditAppointment = (appointment) => {
    setEditAppointment({ ...appointment });
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/doctors/${id}/appointments/${appointmentId}`
      );
      fetchDoctorData();
      alert('Запись успешно удалена');
    } catch (error) {
      console.error('Ошибка при удалении записи:', error);
    }
  };

  const handleSaveAppointment = async () => {
    // Валидация даты
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if (!datePattern.test(editAppointment.date)) {
      setErrors({ date: 'Введите дату в формате ДД.ММ.ГГГГ' });
      return;
    }

    // Проверка, является ли дата корректной
    const isValidDate = dayjs(editAppointment.date, 'DD.MM.YYYY', true).isValid();
    if (!isValidDate) {
      setErrors({ date: 'Введите корректную дату' });
      return;
    }

    try {
      const updatedAppointment = {
        ...editAppointment,
        date: editAppointment.date, // Дата уже в формате 'ДД.ММ.ГГГГ'
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/doctors/${id}/appointments/${editAppointment.id}`,
        { appointment: updatedAppointment }
      );

      setEditAppointment(null);
      fetchDoctorData();
      alert('Запись успешно обновлена');
    } catch (error) {
      console.error('Ошибка при обновлении записи:', error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditAppointment({ ...editAppointment, [name]: value });
    // Очистка ошибок при изменении полей
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  return (
    <>
      {doctor ? (
        <>
          <h2 className="flex justify-center gap-2 w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black text-lg font-montserrat">
            <span>Личный кабинет</span>
            <span className="font-medium">- {doctor.name}</span>
          </h2>
          <button
            onClick={handleLogout}
            className="bg-bgdarkgray hover:bg-bgdarkgray text-white hover:text-white hover:bg-graytext p-2 rounded"
          >
            ВЫХОД
          </button>
          <div className="my-4">
            <button
              onClick={() => setPersonalInfoCollapsed(!isPersonalInfoCollapsed)}
              className="bg-mainblue hover:bg-maingreen  text-white p-2 rounded mb-4 flex items-center"
            >
              Личные данные{' '}
              {isPersonalInfoCollapsed ? <ArrowDropDown /> : <ArrowDropUp />}
            </button>
            {!isPersonalInfoCollapsed && (
              <div className="border p-4 rounded mb-4">
                <div className="mb-4">
                  <label className="block text-sm mb-2">Имя:</label>
                  <input
                    type="text"
                    name="name"
                    value={doctor.name || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={doctor.email || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Специальность:</label>
                  <input
                    type="text"
                    name="specialty"
                    value={doctor.specialty || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">
                    Опыт (вписывать только цифру):
                  </label>
                  <input
                    type="number"
                    name="experience"
                    value={doctor.experience || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">
                    Описание/подробнее резюме о враче:
                  </label>
                  <textarea
                    name="about"
                    value={doctor.about || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Образование:</label>
                  <textarea
                    name="education"
                    value={doctor.education || ''}
                    onChange={handleDataChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Фото врача:</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <button
              onClick={() => setScheduleCollapsed(!isScheduleCollapsed)}
              className="bg-mainblue hover:bg-maingreen  text-white p-2 rounded mb-4 flex items-center"
            >
              График работы{' '}
              {isScheduleCollapsed ? <ArrowDropDown /> : <ArrowDropUp />}
            </button>
            {!isScheduleCollapsed && (
              <div className="border p-4 rounded mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Расписание работы:
                  </label>
                  {DAYS_OF_WEEK.map((day) => (
                    <div key={day} className="mb-4">
                      <div className="flex items-center mb-2">
                        <span className="w-32">{day}:</span>
                        <IconButton onClick={() => handleAddTimeSlot(day)}>
                          <AddCircleOutline />
                        </IconButton>
                      </div>
                      {(schedule[day] || []).map((timeSlot, index) => (
                        <div
                          key={index}
                          className="flex items-center mb-2 ml-8"
                        >
                          <input
                            type="number"
                            min="0"
                            max="23"
                            value={timeSlot.start || ''}
                            onChange={(e) =>
                              handleScheduleChange(
                                day,
                                index,
                                'start',
                                e.target.value
                              )
                            }
                            className="w-14 p-2 border rounded mr-2"
                          />
                          <span>ч.</span>
                          <span className="mx-2">-</span>
                          <input
                            type="number"
                            min="0"
                            max="23"
                            value={timeSlot.end || ''}
                            onChange={(e) =>
                              handleScheduleChange(
                                day,
                                index,
                                'end',
                                e.target.value
                              )
                            }
                            className="w-14 p-2 border rounded mr-2"
                          />
                          <span>ч.</span>
                          <IconButton
                            onClick={() => handleRemoveTimeSlot(day, index)}
                            color="error"
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  ))}
                  <p className="text-sm text-mediumgray mt-2 text-center">
                    Добавьте временные промежутки или оставьте пустым, если вы не
                    работаете в этот день.
                  </p>
                  <p className=" text-red py-2 px-10">
                    При очистке расписания, не забудьте нажать
                    <IconButton color="error">
                      <RemoveCircleOutline />
                    </IconButton>{' '}
                    чтобы день/временной промежуток не был доступен для записи на
                    прием пользователями
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end ">
            <button
              onClick={handleSaveData}
              className="bg-maingreen hover:bg-orange text-white p-2 rounded my-2"
            >
              Сохранить изменения
            </button>
          </div>

          {/* Записи на прием */}
          <h2 className="text-xl font-bold mt-6 mb-4">Записи на прием</h2>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="appointments-content"
              id="appointments-header"
            >
              <Typography variant="h6">
                Актуальные записи на ближайшие 14 дней
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {appointments.length === 0 ? (
                <Typography>Нет записей на прием.</Typography>
              ) : (
                appointments.map((appointment) => (
                  <Card key={appointment.id} variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="body1">
                        <strong>Дата:</strong> {appointment.date}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Время:</strong> {appointment.time}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Пациент:</strong> {appointment.name}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Email:</strong> {appointment.email}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Телефон:</strong> {appointment.phone}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Специализации:</strong>{' '}
                        {appointment.specialties?.length > 0 
                          ? appointment.specialties.join(', ') 
                          : 'Не указаны'}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEditAppointment(appointment)}
                      >
                        Редактировать
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteAppointment(appointment.id)}
                      >
                        Удалить
                      </Button>
                    </CardActions>
                  </Card>
                ))
              )}
            </AccordionDetails>
          </Accordion>

          {editAppointment && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded shadow-md">
                <h2 className="text-xl font-bold mb-4">Редактировать запись</h2>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Дата:</label>
                  <TextField
                    name="date"
                    value={editAppointment.date}
                    onChange={handleEditChange}
                    placeholder="ДД.ММ.ГГГГ"
                    InputProps={{
                      inputComponent: DateMaskInput,
                    }}
                    error={Boolean(errors.date)}
                    helperText={errors.date}
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Время:</label>
                  <TextField
                    type="time"
                    name="time"
                    value={editAppointment.time}
                    onChange={handleEditChange}
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Пациент:</label>
                  <TextField
                    name="name"
                    value={editAppointment.name}
                    onChange={handleEditChange}
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Email:</label>
                  <TextField
                    type="email"
                    name="email"
                    value={editAppointment.email}
                    onChange={handleEditChange}
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm mb-2">Телефон:</label>
                  <TextField
                    name="phone"
                    value={editAppointment.phone}
                    onChange={handleEditChange}
                    fullWidth
                  />
                </div>
                <div className="flex justify-between gap-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditAppointment(null)}
                    fullWidth
                  >
                    Отменить
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveAppointment}
                    fullWidth
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>Загрузка данных...</p>
      )}
    </>
  );
};

export default DoctorDashboard;
