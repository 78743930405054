import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ru');

const ManageAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();

  const fetchAllAppointments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/doctors`
      );
      const doctors = response.data;

      const allAppointments = [];
      doctors.forEach((doctor) => {
        if (doctor.appointments && doctor.appointments.length > 0) {
          doctor.appointments.forEach((appointment) => {
            allAppointments.push({
              ...appointment,
              doctorId: doctor.id,
              doctorName: doctor.name,
              specialties: appointment.specialties || 'Не указаны', // ## Обработка specialties
            });
          });
        }
      });

      setAppointments(allAppointments);
    } catch (error) {
      console.error('Ошибка при получении списка врачей:', error);
    }
  };

  useEffect(() => {
    fetchAllAppointments();

    const intervalId = setInterval(() => {
      fetchAllAppointments();
      console.log('Записи на прием обновлены');
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleEditAppointment = (appointment) => {
    navigate(`/manage-appointments/${appointment.doctorId}/${appointment.id}`, {
      state: { appointment },
    });
  };

  const handleDeleteAppointment = async (doctorId, appointmentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/doctors/${doctorId}/appointments/${appointmentId}`
      );
      fetchAllAppointments();
      alert('Запись успешно удалена');
    } catch (error) {
      console.error('Ошибка при удалении записи:', error);
    }
  };

  const filterAndSortAppointments = (startDate, endDate) => {
    console.log('Фильтрация записей:');
    console.log(`Начало периода: ${startDate.format('DD.MM.YYYY HH:mm')}`);
    console.log(`Конец периода: ${endDate.format('DD.MM.YYYY HH:mm')}`);

    const filteredAppointments = appointments.filter((appointment) => {
      const appointmentDateTime = dayjs.tz(
        `${appointment.date} ${appointment.time}`,
        'DD.MM.YYYY HH:mm',
        'Europe/Moscow'
      );
      return appointmentDateTime.isBetween(startDate, endDate, 'minute', '[]');
    });

    console.log(`Количество записей после фильтрации: ${filteredAppointments.length}`);
    return filteredAppointments.sort((a, b) => {
      const dateTimeA = dayjs.tz(
        `${a.date} ${a.time}`,
        'DD.MM.YYYY HH:mm',
        'Europe/Moscow'
      );
      const dateTimeB = dayjs.tz(
        `${b.date} ${b.time}`,
        'DD.MM.YYYY HH:mm',
        'Europe/Moscow'
      );
      return dateTimeA.diff(dateTimeB);
    });
  };

  const getFutureAppointments = () => {
    const now = dayjs().tz('Europe/Moscow');
    const twoWeeksLater = now.add(14, 'day');
    console.log('Функция getFutureAppointments:');
    return filterAndSortAppointments(now, twoWeeksLater);
  };

  const getPastAppointments = () => {
    const now = dayjs().tz('Europe/Moscow');
    const twoWeeksAgo = now.subtract(14, 'day');
    console.log('Функция getPastAppointments:');
    return filterAndSortAppointments(twoWeeksAgo, now);
  };

  const getAllAppointments = () => {
    const now = dayjs().tz('Europe/Moscow');
    const twoWeeksLater = now.add(14, 'day');
    console.log('Функция getAllAppointments:');
    return filterAndSortAppointments(now, twoWeeksLater);
  };

  const groupAppointmentsByDoctor = (appointmentsList) => {
    const appointmentsByDoctor = {};
    appointmentsList.forEach((appointment) => {
      const { doctorId, doctorName } = appointment;
      if (!appointmentsByDoctor[doctorId]) {
        appointmentsByDoctor[doctorId] = {
          doctorId,
          doctorName,
          appointments: [],
        };
      }
      appointmentsByDoctor[doctorId].appointments.push(appointment);
    });
    return Object.values(appointmentsByDoctor);
  };

  const allAppointments = getAllAppointments();
  const futureAppointmentsByDoctor = groupAppointmentsByDoctor(
    getFutureAppointments()
  );
  const pastAppointments = getPastAppointments();

  return (
    <div>
      <h2 className="flex justify-center w-full my-2 tablet:mb-4 py-2 pl-4 rounded-lg bg-bggray text-black font-montserrat text-xl">
        Панель управления записями на прием
      </h2>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="all-appointments-content"
          id="all-appointments-header"
        >
          <Typography variant="h6 w-full"><div className='w-full flex flex-row justify-between'><p>Все записи</p><p>Текущая дата : +14 дней</p></div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {allAppointments.length === 0 ? (
            <Typography>Нет записей на прием.</Typography>
          ) : (
            allAppointments.map((appointment) => (
              <Card
                key={`${appointment.doctorId}-${appointment.id}`}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                <CardContent>
                  <Typography variant="body1">
                    <strong>Дата:</strong> {appointment.date}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Время:</strong> {appointment.time}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Врач:</strong> {appointment.doctorName}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Пациент:</strong> {appointment.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Email:</strong> {appointment.email}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Телефон:</strong> {appointment.phone}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Специализации:</strong>{' '}
                    {appointment.specialties?.length > 0 
                      ? appointment.specialties.join(', ') 
                      : 'Не указаны'}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditAppointment(appointment)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleDeleteAppointment(
                        appointment.doctorId,
                        appointment.id
                      )
                    }
                  >
                    Удалить
                  </Button>
                </CardActions>
              </Card>
            ))
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="future-appointments-content"
          id="future-appointments-header"
        >
          <Typography variant="h6 w-full"><div className='w-full flex flex-row justify-between'><p>По докторам</p><p>Текущая дата : +14 дней</p></div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {futureAppointmentsByDoctor.length === 0 ? (
            <Typography>Нет записей на прием.</Typography>
          ) : (
            futureAppointmentsByDoctor.map((doctor) => (
              <Accordion key={doctor.doctorId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`future-appointments-doctor-${doctor.doctorId}-content`}
                  id={`future-appointments-doctor-${doctor.doctorId}-header`}
                >
                  <Typography variant="subtitle1">{doctor.doctorName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {doctor.appointments.map((appointment) => (
                    <Card
                      key={`${appointment.doctorId}-${appointment.id}`}
                      variant="outlined"
                      sx={{ mb: 2 }}
                    >
                      <CardContent>
                        <Typography variant="body1">
                          <strong>Дата:</strong> {appointment.date}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Время:</strong> {appointment.time}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Пациент:</strong> {appointment.name}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Email:</strong> {appointment.email}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Телефон:</strong> {appointment.phone}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Специализации:</strong>{' '}
                          {appointment.specialties?.length > 0 
                            ? appointment.specialties.join(', ') 
                            : 'Не указаны'}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditAppointment(appointment)}
                        >
                          Редактировать
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            handleDeleteAppointment(
                              appointment.doctorId,
                              appointment.id
                            )
                          }
                        >
                          Удалить
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="past-appointments-content"
          id="past-appointments-header"
        >
          <Typography variant="h6 w-full"><div className='w-full flex flex-row justify-between'><p>Прошедшие</p><p>Текущая дата : -14 дней</p></div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {pastAppointments.length === 0 ? (
            <Typography>Нет прошедших записей на прием.</Typography>
          ) : (
            pastAppointments.map((appointment) => (
              <Card
                key={`${appointment.doctorId}-${appointment.id}`}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                <CardContent>
                  <Typography variant="body1">
                    <strong>Дата:</strong> {appointment.date}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Время:</strong> {appointment.time}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Врач:</strong> {appointment.doctorName}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Пациент:</strong> {appointment.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Email:</strong> {appointment.email}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Телефон:</strong> {appointment.phone}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Специализации:</strong>{' '}
                    {appointment.specialties?.length > 0 
                      ? appointment.specialties.join(', ') 
                      : 'Не указаны'}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditAppointment(appointment)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleDeleteAppointment(
                        appointment.doctorId,
                        appointment.id
                      )
                    }
                  >
                    Удалить
                  </Button>
                </CardActions>
              </Card>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ManageAppointments;